<template>
    <button class="bg-red-600 hover:bg-red-700 text-white px-4 py-1 rounded-md text-sm" data-item-id="{{$lead->id}}"
        data-confirm="Eintrag endgültig löschen?" @click="openModal">
        <font-awesome-icon :icon="['fas', icon]" class="text-sm" />
    </button>

    <abstract-modal :is-open="isModalOpen" :title="modalTitle" @closeModal="closeModal">
        <template v-slot:modalBody>
            <div class="p-5">
                <p class="mb-5">
                    {{ action === 'delete'
                        ? `Soll das Lead mit der ID ${leadId} wirklich gelöscht werden?`
                        : `Soll das Lead mit der ID ${leadId} wirklich geschlossen werden?` }}
                </p>
                <!-- http status notifier -->
                <div v-if="httpResult === 'success'"
                    class="flex items-center text-green-500 bg-green-100 border border-green-400 px-4 py-3 rounded-lg shadow">
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="text-sm mr-2" />
                    <p>Aktion wurde erfolgreich durchgeführt.</p>
                </div>
                <div v-else-if="httpResult === 'error'"
                    class="flex items-center text-red-500 bg-red-100 border border-red-400 px-4 py-3 rounded-lg shadow">
                    <font-awesome-icon :icon="['fas', 'times-circle']" class="text-sm mr-2" />
                    <p>Es ist ein Fehler aufgetreten.</p>
                </div>
                <div class="flex justify-end">
                    <button
                        class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:shadow-xl transition duration-200 ease-in-out transform hover:-translate-y-1"
                        @click="action === 'delete' ? deleteLead(leadId) : closeLead(leadId)">Ja</button>
                    <button
                        class="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 ml-2 rounded-lg shadow-lg hover:shadow-xl transition duration-200 ease-in-out transform hover:-translate-y-1"
                        @click="closeModal">Nein</button>
                </div>

            </div>
        </template>
    </abstract-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AbstractModal from "@/components/AbstractModal.vue";

enum ActionKind {
    Delete = 'delete',
    Close = 'close',
}

export default defineComponent({
    components: {
        FontAwesomeIcon,
        AbstractModal,
    },
    props: {
        leadId: {
            type: Number,
            required: true,
        },
        action: {
            type: String,
            required: true,
            validator: (value: string) => Object.values(ActionKind).includes(value),
        },
        icon: {
            type: String,
            required: true,
            default: 'trash-alt',
        },
        retract: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isModalOpen: false,
            modalTitle: '',
            httpResult: null,
            httpError: "418 I'm a teapot",
            httpResponse: null,
        };
    },
    mounted() {
        // set the modal title based on the action
        this.modalTitle = this.action === ActionKind.Delete ? 'Eintrag löschen' : 'Eintrag schließen';
    },
    methods: {
        openModal() {
            this.isModalOpen = true;
            this.modalTitle = this.action === ActionKind.Delete ? 'Eintrag löschen' : 'Eintrag schließen';
        },
        closeModal() {
            this.isModalOpen = false;
        },
        deleteLead(id: number) {
            console.log('delete', id);
            // send delete request to /leads/{id} then let user know and refresh the page
            fetch(`/leads/${id}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
            }).then((response) => {
                if (response.status === 200) {
                    this.httpResult = 'success';
                } else {
                    this.httpResult = 'error';
                    this.httpError = response.statusText;
                    this.httpResponse = response;
                }
            }).catch((reason) => {
                this.httpResult = 'error';
                this.httpError = reason;
            }).finally(() => {
                setTimeout(() => {
                    this.isModalOpen = false;
                    location.reload();
                }, 2000);
            });
        },
        closeLead(id: number) {
            // if retract is true add "retract=true" to the body, else set it to "state=closed"
            fetch(`/leads/${id}/updateDeprecated`, {
                method: 'PUT',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: this.retract ? 'retract=true' : 'state=closed',
            }).then((response) => {
                if (response.status === 200) {
                    this.httpResult = 'success';
                } else {
                    this.httpResult = 'error';
                }
            }).catch(() => {
                this.httpResult = 'error';
            }).finally(() => {
                setTimeout(() => {
                    this.isModalOpen = false;
                    location.reload();
                }, 2000);
            });
        },
    },
});
</script>